import React from "react"
import { graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutShort from "../components/aboutshort"

class Glossary extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Traveller's Glossary" />
          <div className="text-center px-6 py-12 mb-6 bg-gray-100 border-b">
            <h1 className="text-xl md:text-4xl pb-4">Traveller's Glossary</h1>
          </div>
          <div className="simple-post container max-w-5xl mx-auto py-8">
            <p>
              <dl>
                <dt>Business Class (plane)</dt>
                <dd>
                  <p>A section of the plane and set of ammenities conducive to getting work done.</p>
                  <p><em>I flew business class and fell asleep instead of working.</em></p>
                </dd>

                <dt>Economy Class (plane)</dt>
                <dd>
                  <p>The smallest passenger seats on the plane, usually the most affordable.</p>
                  <p><em>I cuddled up with the stranger next to me in economy.</em></p>
                </dd>

                <dt>Premium Economy Class (plane)</dt>
                <dd>
                  <p>An experience nicer than economy, but not as nice as business. Each airline has its own ideas.</p>
                </dd>

                <dt><a name="roaming"></a>Roaming</dt>
                <dd>
                  <p>Using a SIM card on a network that it isn't directly subscribed to.</p>
                  <p><em>My phone roamed into another country, now I've got a large bill to pay!</em></p>
                </dd>

                <dt>SIM Card</dt>
                <dd>
                  <p>The little piece of plastic that is inserted in to a mobile phone so it knows which network to connect to.</p>
                </dd>
              </dl>
            </p>
          </div>
        
      </Layout>
    )
  }
}

export default Glossary

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`